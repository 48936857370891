import { OnClickLoadingButton } from "@/components/shared/Button";
import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import SignOutPopup from "@/components/shared/SignOutPopup";
import { colors } from "@/styles/global.styles";
import { logoutEvent } from "@/utils/helpers/removeDevice";
import { ss } from "@/utils/helpers/sessionStorage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { ls } from "../../utils/helpers/localstorage";
import {
  authenticateDirector,
  DEFAULT_AVATAR,
  OdienceUser,
} from "../../utils/hooks/useDirectorAuthentication";
import EmailList from "./components/EmailList";
import { useProfileModal } from "./utils/ProfileModalContext";
import {
  checkIfFieldInUse,
  DISPLAY_NAME_MESSAGE,
  isValidDisplayName,
  removeAuthenticatedUserVerifiedEmail,
  saveUpdatedUserProfile,
} from "./utils/ProfileScreenUtils";
import {
  actionsGroup,
  addImgBtn,
  buttonEditRow,
  buttonRow,
  closeBtn,
  cropButton,
  errorMessage,
  imageModal,
  imageModalBackground,
  imgEditor,
  mainProfileBody,
  profileButton,
  profileDiv,
  profileFooter,
  profileHeader,
  profileImg,
  profileInput,
  profilePictureCol,
  removeImgBtn,
  signOut,
  subHeader,
  subHeaderRow,
  title,
} from "./utils/ProfileStyles";

const ProfileScreen = () => {
  const {
    showModal,
    closeModal,
    updateUserAvatar,
    updateUserDisplayName,
    updateUserEmail,
  } = useProfileModal();

  const [authenticatedUser, setAuthenticatedUser] =
    useState<OdienceUser | null>(null);
  const [firstName, setFirstName] = useState(() => ls.getUserFirstName());
  const [lastName, setLastName] = useState(() => ls.getUserLastName());
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");

  const [emailTouched] = useState(false);
  const [displayNameTouched, setDisplayNameTouched] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [, setEmailError] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null!);

  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(DEFAULT_AVATAR);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [editedImage, setEditedImage] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isLocalFile, setIsLocalFile] = useState(false);
  const [, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState<Blob | null>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showRemoveEmail, setShowRemoveEmail] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<string>("");
  const [signoutOverlayOpen, setSignoutOverlayOpen] = useState(false);

  const getEmailInvite = ss.getEmail();
  const removeEmailInvite = () => ss.removeEmail();

  const invitedId = ss.getInvite();
  const invitedEventId = ss.getInvitedEventId();

  useEffect(() => {
    if (showModal) {
      const fetchUser = async () => {
        try {
          const odienceUser = await authenticateDirector();

          if (odienceUser) {
            setAuthenticatedUser(odienceUser);
            setDisplayName(odienceUser.name);

            if (odienceUser.email === null || odienceUser.email === "null") {
              setEmail(odienceUser.email || "");
            }

            if (odienceUser.avatar === "" || odienceUser.avatar === null) {
              setPreviewImage(DEFAULT_AVATAR);
              setEditedImage("");
            } else {
              setPreviewImage(odienceUser.avatar);
              setEditedImage(odienceUser.avatar);
            }
          }
        } catch (error) {
          console.error("Error fetching user: ", error);
        }
      };

      void fetchUser();
    }
  }, [showModal]);

  if (!authenticatedUser) return;

  const updateUserEmailList = async () => {
    const odienceUser = await authenticateDirector();
    if (odienceUser) {
      setAuthenticatedUser(odienceUser);
    }
  };

  const toggleRemoveEmail = (email: string) => {
    setSelectedEmail(email);
    setShowRemoveEmail(!showRemoveEmail);
  };

  const handleRemoveEmail = async () => {
    try {
      await removeAuthenticatedUserVerifiedEmail(
        authenticatedUser,
        selectedEmail
      );
      await updateUserEmailList();
      toggleRemoveEmail("");
    } catch (error) {
      console.error("Error removing email: ", error);
    }
  };

  const confirmationMessage = `Are you sure you want to remove ${selectedEmail} from your list of verified emails?`;

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedArea);

    // Create a canvas to hold the cropped image
    const canvas = document.createElement("canvas");
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      const image = new Image();
      if (!isLocalFile) {
        image.crossOrigin = "anonymous";
      }
      image.src = previewImage; // make sure to always set the src before the onload

      image.onload = () => {
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const x = croppedAreaPixels.x * scaleX;
        const y = croppedAreaPixels.y * scaleY;
        const width = croppedAreaPixels.width * scaleX;
        const height = croppedAreaPixels.height * scaleY;

        ctx.drawImage(
          image,
          x,
          y,
          width,
          height,
          0,
          0,
          canvas.width,
          canvas.height
        );

        // Convert canvas to blob and set it
        canvas.toBlob((blob) => {
          if (blob) {
            setCroppedImage(blob);
          }
        }, "image/jpeg");
      };

      image.onerror = (error) => {
        console.error("Image load error", error);
      };

      console.log("Image object created", image);
    }
  };
  const handleImageChange = (e) => {
    setIsLocalFile(true);
    const file = e.target.files[0];
    setSelectedImage(file);
    const imageUrl = URL.createObjectURL(file);
    setPreviewImage(imageUrl);
    setEditedImage(imageUrl);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setIsLocalFile(false);
    if (croppedImage) {
      const croppedImageUrl = URL.createObjectURL(croppedImage);
      setEditedImage(croppedImageUrl);
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setPreviewImage(croppedImageUrl);
    }
  };
  const cancelEdit = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setIsImageModalOpen(false);
  };
  const handleImageUpload = () => {
    fileInputRef.current.click();
  };

  const clearImageAndInput = () => {
    setSelectedImage(null);
    setPreviewImage(DEFAULT_AVATAR);
    setCroppedImage(null);
    setEditedImage("");
    fileInputRef.current.value = "";
  };

  const handleSaveUser = async () => {
    setNameError(false);

    if (!isValidDisplayName(displayName)) {
      setNameError(true);
      return;
    }

    if (authenticatedUser && authenticatedUser.name !== displayName) {
      const isNameInUse = await checkIfFieldInUse(
        authenticatedUser.group_id,
        "usernameExists",
        displayName
      );
      if (isNameInUse) {
        setNameError(true);
        return;
      }
    }

    if (
      authenticatedUser &&
      email.length > 0 &&
      authenticatedUser.email !== email
    ) {
      const isEmailInUse = await checkIfFieldInUse(
        authenticatedUser.group_id,
        "emailExists",
        email
      );
      if (isEmailInUse) {
        setEmailError(true);
        return;
      }
    }

    if (
      await saveUpdatedUserProfile(
        authenticatedUser,
        firstName,
        lastName,
        displayName,
        email,
        getEmailInvite,
        invitedId,
        invitedEventId,
        updateUserAvatar,
        updateUserDisplayName,
        updateUserEmail,
        editedImage,
        selectedImage,
        croppedImage
      )
    ) {
      closeModal();
    }
  };

  const handleCancel = () => {
    const avatar =
      authenticatedUser.avatar! == ""
        ? DEFAULT_AVATAR
        : authenticatedUser.avatar!;
    setIsLocalFile(false);
    setPreviewImage(avatar);
    setEditedImage(avatar);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setDisplayName(authenticatedUser.name);
    setFirstName(ls.getUserFirstName());
    setLastName(ls.getUserLastName());
    removeEmailInvite();
    ss.removeInvite();
    setDropdownOpen(false);
    closeModal();
  };

  const handleDisplayNameInputChange = (e) => {
    setDisplayName(e.target.value);
    setNameError(false);
  };

  const handleDisplayNameInputBlur = () => {
    setDisplayNameTouched(true);
  };

  if (!showModal) {
    return null;
  }

  const toggleSignOutOverlay = () => {
    setSignoutOverlayOpen(!signoutOverlayOpen);
  };

  return (
    <>
      <div css={mainProfileBody}>
        {isImageModalOpen && (
          <div css={imageModalBackground}>
            <div css={imageModal}>
              <div css={imgEditor}>
                <Cropper
                  image={editedImage}
                  crop={crop}
                  zoom={zoom}
                  aspect={3 / 3}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div css={buttonEditRow}>
                <button
                  type="button"
                  css={cropButton}
                  onClick={closeImageModal}
                >
                  Crop
                </button>
                <button type="button" css={cropButton} onClick={cancelEdit}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        <div css={profileDiv}>
          <div css={profileHeader}>
            <div></div>
            <div css={title}>Profile</div>
            <img
              css={closeBtn}
              src="/odience/event/white-close.svg"
              alt=""
              onClick={handleCancel}
            />
          </div>

          <div onClick={handleImageUpload} css={profilePictureCol}>
            <img src={previewImage} css={profileImg} alt="Profile" />
            {previewImage === DEFAULT_AVATAR && (
              <div
                css={addImgBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  handleImageUpload();
                }}
              >
                Add Photo
              </div>
            )}
            {previewImage !== DEFAULT_AVATAR && (
              <div
                title="Remove"
                css={removeImgBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  clearImageAndInput();
                }}
              >
                <img src="/odience/event/white-close.svg" alt="" />
              </div>
            )}
            {previewImage !== DEFAULT_AVATAR && (
              <div
                css={addImgBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsImageModalOpen(true);
                }}
              >
                Edit
              </div>
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </div>

          <div css={subHeaderRow}>
            <div css={subHeader}>Display Name</div>
            {!displayName && displayNameTouched && (
              <span css={errorMessage}>Please enter a Display Name</span>
            )}
            {nameError && (
              <span css={errorMessage}>
                Username is not valid or is already taken
              </span>
            )}
          </div>

          <div css={profileInput}>
            <input
              type="text"
              placeholder="Display Name"
              value={displayName || ""}
              onChange={handleDisplayNameInputChange}
              onBlur={handleDisplayNameInputBlur}
              required
            />
          </div>

          {authenticatedUser.emails && authenticatedUser.emails?.length > 0 && (
            <div css={{ width: "100%", marginTop: "1em" }}>
              <div
                css={[
                  subHeaderRow,
                  { width: "100%", justifyContent: "space-between" },
                ]}
              >
                <h2 css={subHeader}>VERIFIED EMAILS</h2>
                <IconButton
                  onClick={toggleDropdown}
                  css={{ color: colors.primaryTextColor }}
                >
                  <ExpandMoreIcon
                    css={{
                      transition: "transform 0.3s ease",
                      fontWeight: 700,
                      height: "1.25em",
                      width: "1.25em",
                      transform: isDropdownOpen
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                  />
                </IconButton>
              </div>
              {authenticatedUser.email && (
                <EmailList
                  authenticatedUser={authenticatedUser}
                  isDropdownOpen={isDropdownOpen}
                  primaryEmail={authenticatedUser.email}
                  toggleRemoveEmail={toggleRemoveEmail}
                />
              )}
            </div>
          )}

          <div css={profileFooter}>
            <div css={{ color: colors.secondaryTextColor, fontSize: "0.7rem" }}>
              {DISPLAY_NAME_MESSAGE}
            </div>
            <div css={actionsGroup}>
              <div css={signOut} onClick={toggleSignOutOverlay}>
                <LogoutOutlinedIcon />
                &nbsp;Sign Out
              </div>
              <div css={buttonRow}>
                <button
                  type="button"
                  onClick={handleCancel}
                  css={profileButton}
                >
                  Cancel
                </button>
                <OnClickLoadingButton
                  disabled={!displayName && !emailTouched}
                  onClick={handleSaveUser}
                  css={[
                    profileButton,
                    { backgroundColor: colors.primaryAccentColor },
                  ]}
                >
                  Save
                </OnClickLoadingButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showRemoveEmail && (
        <ConfirmationPopup
          title="Remove Email"
          togglePopup={() => toggleRemoveEmail(selectedEmail)}
          confirmationMessage={confirmationMessage}
          handleAction={handleRemoveEmail}
          primaryButtonText="Confirm"
          closeButtonActive={true}
        />
      )}
      {signoutOverlayOpen && (
        <SignOutPopup
          toggleOverlay={toggleSignOutOverlay}
          logoutEvent={logoutEvent}
        />
      )}
    </>
  );
};

export default ProfileScreen;
