import DocumentTitle from "@/components/DocumentTitle";
import InternetStatus from "@/components/connectivityIssues/InternetStatus";

import ChatScreenOverlay from "@/components/chatScreen/ChatScreenOverlay";
import Notifications from "@/components/notifications/Notifications";
import NotificationsInvite from "@/components/shared/NotificationsInvite";
import SketchOverlays from "@/components/sketch/SketchOverlays";
import { atoms } from "@/utils/helpers/atoms";
import { useLogin } from "@/utils/hooks/useLogin";
import { useOdienceOrganization } from "@/utils/hooks/useOdienceOrganization";
import { Global } from "@emotion/react";
import { useAtomValue } from "jotai";
import { useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import CallOverlays from "../../components/callOverlays/CallOverlays";
import Navigation from "../../components/navigation/Navigation";
import NavigationProgress from "../../components/navigation/NavigationProgress";
import { globalStyles } from "../../styles/global.styles";
import WelcomeOverlay from "../WelcomeOverlay";
import ProfileScreen from "../profile/ProfileScreen";
import { LayoutContext } from "../profile/utils/LayoutContext";
import { ProfileModalProvider } from "../profile/utils/ProfileModalContext";
import { Root, RootWrapper } from "./Layout.style";
import VerseServices from "./VerseServices";

export default function Layout() {
  const { embeddedMode } = useOdienceOrganization();
  const layoutContextValue = useMemo(() => {
    return { hasNavBar: !embeddedMode };
  }, [embeddedMode]);

  // the isLoggedInAtom is required to react if login fails and relogin succeeds.
  const isLoggedIn = useAtomValue(atoms.provisioning.isLoggedIn);
  useLogin();

  const launchServices = isLoggedIn;

  const location = useLocation();

  useEffect(() => {
    const originalBackgroundColor = "#000e1a";

    if (location.pathname.includes("event/stream")) {
      document.body.style.backgroundColor = "black";
    } else {
      document.body.style.backgroundColor = originalBackgroundColor;
    }
  }, [location]);

  return (
    <LayoutContext value={layoutContextValue}>
      <Global styles={globalStyles} />
      {launchServices && <VerseServices />}
      <DocumentTitle />
      <NavigationProgress />
      <InternetStatus />
      <NotificationsInvite />
      <ProfileModalProvider>
        <RootWrapper style={embeddedMode ? { padding: "0" } : {}}>
          <Root>
            {!embeddedMode && <WelcomeOverlay />}
            <ProfileScreen />
            {!embeddedMode && <Navigation />}
            <Outlet />
            {launchServices && <CallOverlays />}
            {launchServices && <ChatScreenOverlay />}
            {launchServices && <SketchOverlays />}
            {launchServices && <Notifications />}
          </Root>
        </RootWrapper>
      </ProfileModalProvider>
    </LayoutContext>
  );
}
