import { colors } from "@/styles/global.styles";
import { OdienceUser } from "@/utils/hooks/useDirectorAuthentication";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

type EmailListProps = {
  authenticatedUser: OdienceUser;
  isDropdownOpen: boolean;
  primaryEmail: string;
  toggleRemoveEmail: (email: string) => void;
};

const EmailList = ({
  authenticatedUser,
  isDropdownOpen,
  primaryEmail,
  toggleRemoveEmail,
}: EmailListProps) => {
  if (!authenticatedUser || authenticatedUser.emails.length === 0) return null;

  const { emails } = authenticatedUser;

  return (
    isDropdownOpen && (
      <div>
        {emails.map((email, index) => {
          const isPrimary = primaryEmail ? email === primaryEmail : index === 0;
          return (
            <div
              key={email}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "8px",
                width: "100%",
                color: colors.secondaryTextColor,
              }}
            >
              <div>{email}</div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                {isPrimary && (
                  <span style={{ color: colors.secondaryAccentColor }}>
                    Primary Email
                  </span>
                )}
                <IconButton
                  aria-label={`Remove email ${email}`}
                  css={{
                    color: colors.primaryAccentColor,
                    ":disabled": {
                      color: colors.secondaryTextColor,
                      pointerEvents: "none",
                    },
                  }}
                  onClick={() => toggleRemoveEmail(email)}
                  disabled={isPrimary}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};

export default EmailList;
