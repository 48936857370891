import { replaceReactionEmoji } from "@/utils/helpers/emoji";
import { getFileContentTypeHumanReadableName } from "@/utils/helpers/fileUtils";
import Conversation from "@/utils/messaging/conversation/Conversation";
import NmsMessage from "@/utils/messaging/NmsMessage";

export function buildNotificationTitle(
  conversation: Conversation,
  inAppNotification: boolean
): string {
  // Conversation always has a name, only case it would not is for group chat with no subject
  return `${inAppNotification ? "" : "New message from "}${conversation.getName(true)}`;
}

export function buildNotificationBody(
  message: NmsMessage | undefined,
  fromRemote: string | undefined,
  desktopNotification: boolean,
  reaction?: string
): string | undefined {
  if (!message) {
    return "";
  }

  let reactedWith = "";
  let reactedWithNoRemote = "";
  if (reaction) {
    if (desktopNotification) {
      reactedWithNoRemote = "Reacted with an emoji";
      reactedWith = " reacted with an emoji";
    } else {
      const reactionImage = replaceReactionEmoji(reaction);
      reactedWithNoRemote = `Reacted ${reactionImage}`;
      reactedWith = ` reacted ${reactionImage} `;
    }
  }

  if (message["Content-Type"] === "text/plain") {
    return `${fromRemote ? `${fromRemote}${reactedWith}: ` : reactedWithNoRemote ? `${reactedWithNoRemote} : ` : ``}${message.payloadParts[0]?.textContent}`;
  } else {
    const fileType = getFileContentTypeHumanReadableName(message);

    if (reaction) {
      return `${fromRemote ? `${fromRemote}${reactedWith}` : reactedWithNoRemote} to ${fileType}`;
    } else {
      return `You received ${fileType}${fromRemote ? ` from ${fromRemote}` : ``}`;
    }
  }
}
