import { useState } from "react";
import ConfirmationPopup from "./ConfirmationPopup";

const notificationBannerParameters =
  window.Notification &&
  window.Notification.permission !== "denied" &&
  window.Notification.permission !== "granted";

const NotificationsInvite = () => {
  const [showBanner, setShowBanner] = useState(notificationBannerParameters);
  const notificationRequested = localStorage.getItem("notificationRequested");

  const requestPermission = () => {
    if (notificationBannerParameters) {
      void window.Notification.requestPermission();
    }

    localStorage.setItem("notificationRequested", "true");
    setShowBanner(false);
  };

  const ignoreNotificationRequest = () => {
    setShowBanner(false);
    localStorage.setItem("notificationRequested", "true");
  };

  return (
    !notificationRequested &&
    showBanner && (
      <ConfirmationPopup
        title="Notifications"
        confirmationMessage="Never miss a call or message! Allow Verse Messages to send you desktop notifications."
        primaryButtonText="Allow"
        handleAction={requestPermission}
        togglePopup={ignoreNotificationRequest}
        secondaryButtonActive={false}
      />
    )
  );
};

export default NotificationsInvite;
