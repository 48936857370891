import incomingCallSound from "@/assets/sounds/IncomingSketchRingTone.mp3";
import outgoingCallSound from "@/assets/sounds/OutgoingSketchRingTone.mp3";
import {
  CapabilityType,
  VerseStatus,
  checkCapabilityOnLocalCache,
} from "@/utils/hooks/useCapabilities";
import { useAtom, useAtomValue } from "jotai";
import { MotionConfig } from "motion/react";
import { useEffect, useRef, useState } from "react";
import useSound from "use-sound";
import WebGwContact from "../../utils/helpers/WebGwContact";

import { sleep } from "@/utils";
import { atoms, resetAtoms } from "@/utils/helpers/atoms";
import { useContacts } from "@/utils/hooks/useContacts";
import Conversation from "@/utils/messaging/conversation/Conversation";
import { base64ToBlob } from "@/utils/messaging/conversation/conversationUtils";
import { isSamePhoneNumber } from "@/utils/messaging/conversation/conversationUtils/phoneNumberUtils";
import {
  acceptSketchInvitation as acceptSketchInviteApi,
  endSketch as endSketchApi,
  rejectSketchInvitation,
  sendSketchBye,
  sendSketchInvite,
} from "@/utils/sketch";
import { SKETCH_EXPORT_FILENAME, SKETCH_EXPORT_IMAGE_TYPE } from ".";
import { overlayTransition } from "../shared/DraggableOverlay";
import InvitePopup from "../shared/InvitePopup";
import { IncomingSketchSessionOverlay } from "./IncomingSketchSessionOverlay";
import { SketchSessionOverlay } from "./SketchSessionOverlay";

/**
 * TODO:
 * - Do we need time out for incoming and outgoing sketch invite in case no response after X seconds?
 * - What to do if call and sketch at the same time? Need UI.
 * - What about multiple sketch at the same time? Need UI.
 *
 */
export default function SketchOverlays() {
  const contacts = useContacts();

  // Atoms
  const outgoingSketchInfosAtom = useAtomValue(
    atoms.sketch.outgoingSketchInfos
  );
  const [incomingSketchInfosAtom, setIncomingSketchInfosAtom] = useAtom(
    atoms.sketch.incomingSketchInfos
  );
  const incomingSketchEndedInfosAtom = useAtomValue(
    atoms.sketch.incomingSketchEndedInfos
  );

  // States and ref
  const sketchIdRef = useRef(1);
  const [sketchActive, setSketchActive] = useState(false);

  const [showSketchSession, setShowSketchSession] = useState(false);
  const resetSketchStateTimeoutRef = useRef<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [contact, setContact] = useState<WebGwContact | undefined>(undefined);
  const [showIncomingInvitation, setShowIncomingInvitation] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(true);
  const [isShowInvitePopup, setShowInvitePopup] = useState(false);
  const [playOutgoingRingTone, outgoingRingToneOptions] =
    useSound(outgoingCallSound);
  const [playRingTone, ringToneOptions] = useSound(incomingCallSound);

  if (contact) {
    const newContact =
      contacts?.findWithNumber(contact.getMainPhoneNumber()) ||
      WebGwContact.fromPhoneNumber(contact.getMainPhoneNumber())!;

    if (
      contact.noNameReturnPhoneNumber() !== newContact.noNameReturnPhoneNumber()
    ) {
      console.log("Updating contact information to ", newContact);
      setContact(newContact);
    }
  }

  useEffect(() => {
    const handleBeforeunload = (event) => {
      if (contact) {
        const confirmationMessage =
          "Are you sure you want to refresh during a sketch? This will end it.";
        event.preventDefault();
        event.returnValue = confirmationMessage; // For older browsers
        return confirmationMessage; // For modern browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, [contact]);

  // End/reject sketch from remote
  useEffect(() => {
    if (!incomingSketchEndedInfosAtom || !contact) {
      return;
    }

    console.log("End sketch request for ", incomingSketchEndedInfosAtom);

    if (
      !isSamePhoneNumber(
        incomingSketchEndedInfosAtom.number,
        contact.getMainPhoneNumber()
      )
    ) {
      console.log("End sketch number does not match current one, ignoring");
      return;
    }

    // Local needs to confirm by sending the end too
    if (sketchActive && incomingSketchEndedInfosAtom.id == 0)
      endSketchApi(contact.getMainPhoneNumber());

    resetSketchStates({ showEndConfirmation: true });
  }, [incomingSketchEndedInfosAtom]);

  // Incoming/accept invitation from remote
  useEffect(() => {
    let incomingWhileEnded = false;
    // End confirmation still there, we reset all except incoming atom that triggered this effect
    if (resetSketchStateTimeoutRef.current) {
      incomingWhileEnded = true;
      resetSketchStates({ resetIncomingAtom: false });
    } else if (sketchActive) {
      return;
    }

    if (!incomingSketchInfosAtom) {
      return;
    }

    console.log(
      "Incoming sketch invitation request for ",
      incomingSketchInfosAtom
    );

    // Remote accepted invitation, check if it was for the same number
    if (!incomingWhileEnded && outgoingSketchInfosAtom) {
      if (
        !isSamePhoneNumber(
          contact?.getMainPhoneNumber(),
          incomingSketchInfosAtom.number
        )
      ) {
        console.log(
          "Incoming invitation number does not match outgoing one, ignoring"
        );
        return;
      }

      setSketchActive(true);
      setShowSketchSession(true);
      stopOutgoingSketchRingtone();
    }
    // Incoming invitation
    else {
      playIncomingSketchRingtone();
      setContactFromPhoneNumber(incomingSketchInfosAtom.number);
      setShowIncomingInvitation(true);
    }
  }, [incomingSketchInfosAtom]);

  // Outgoing sketch invitation
  useEffect(() => {
    if (!outgoingSketchInfosAtom) {
      return;
    }

    console.log(
      "Outgoing sketch invitation request for ",
      outgoingSketchInfosAtom
    );

    // Check if some cached caps
    const res = checkCapabilityOnLocalCache(
      outgoingSketchInfosAtom.number,
      CapabilityType.MESSAGING
    );

    if (res.verseStatus === VerseStatus.NOT_INSTALLED) {
      setShowInvitePopup(true);
      resetSketchStates();
      return;
    }

    setIsFullScreen(!!outgoingSketchInfosAtom.startFullScreen);
    setContactFromPhoneNumber(outgoingSketchInfosAtom.number);

    setShowSketchSession(true);
    playOutgoingSketchRingtone();

    // Set the current sketchId to the one used for the outgoing invitation.
    sketchIdRef.current = outgoingSketchInfosAtom.id;

    sendSketchInvite(outgoingSketchInfosAtom.number, getSketchId());
  }, [outgoingSketchInfosAtom]);

  const endSketch = async (base64?: string) => {
    console.log("endSketch");
    if (!contact) {
      return;
    }

    if (sketchActive && base64) {
      const mimetype = `image/${SKETCH_EXPORT_IMAGE_TYPE}`;
      const file = new File(
        [base64ToBlob(base64, mimetype)],
        SKETCH_EXPORT_FILENAME,
        { type: mimetype }
      );
      Conversation.getOrCreate({
        phoneNumber: contact.getMainPhoneNumber(),
      }).conversation.sendFile(file);
    }

    if (sketchActive) await endSketchApi(contact.getMainPhoneNumber());

    // This delay is needed by server to avoid invite coming again on remote
    await sleep(100);
    sendSketchBye(contact.getMainPhoneNumber());
    resetSketchStates();
  };

  const rejectSketch = () => {
    console.log("rejectSketch");

    if (!contact) {
      return;
    }

    rejectSketchInvitation(contact.getMainPhoneNumber());
    resetSketchStates();
  };

  const acceptSketchInvite = async () => {
    console.log("acceptSketchInvite");

    if (!contact) {
      return;
    }

    setIncomingSketchInfosAtom(undefined);

    await acceptSketchInviteApi(contact.getMainPhoneNumber(), getSketchId());

    stopIncomingSketchRingtone();
    setShowIncomingInvitation(false);
    setSketchActive(true);
    setShowSketchSession(true);
  };

  const playOutgoingSketchRingtone = async () => {
    outgoingRingToneOptions.sound.loop(true);
    playOutgoingRingTone();
  };

  const stopOutgoingSketchRingtone = () => {
    outgoingRingToneOptions.stop();
  };

  const playIncomingSketchRingtone = async () => {
    ringToneOptions.sound.loop(true);
    playRingTone();
  };

  const stopIncomingSketchRingtone = () => {
    ringToneOptions.stop();
  };

  const toggleShowInvitePopup = () => {
    setShowInvitePopup(!isShowInvitePopup);
  };

  const resetSketchStates = ({
    resetIncomingAtom = true,
    showEndConfirmation = false,
  }: { resetIncomingAtom?: boolean; showEndConfirmation?: boolean } = {}) => {
    console.log("resetSketchStates");
    const reset = () => {
      stopOutgoingSketchRingtone();
      stopIncomingSketchRingtone();
      setContact(undefined);
      setShowSketchSession(false);
      setIsFullScreen(true);
      setShowIncomingInvitation(false);
      setSketchActive(false);
      if (resetSketchStateTimeoutRef.current) {
        clearTimeout(resetSketchStateTimeoutRef.current);
        resetSketchStateTimeoutRef.current = null;
      }
      resetAtoms({
        atomsToSelect: atoms.sketch,
        ...(!resetIncomingAtom && {
          atomsToExclude: [atoms.sketch.incomingSketchInfos],
        }),
      });
    };

    if (showEndConfirmation) {
      if (resetSketchStateTimeoutRef.current) {
        clearTimeout(resetSketchStateTimeoutRef.current);
      }

      resetSketchStateTimeoutRef.current = setTimeout(() => {
        reset();
        resetSketchStateTimeoutRef.current = null;
      }, 2000);
    } else {
      reset();
    }
  };

  const setContactFromPhoneNumber = (phoneNumber: string) => {
    setContact(
      contacts?.findWithNumber(phoneNumber) ||
        WebGwContact.fromPhoneNumber(phoneNumber)
    );
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const getSketchId = () => {
    return sketchIdRef.current++;
  };

  return (
    <MotionConfig transition={overlayTransition}>
      {/* <AnimatePresence>  TODO: Fix animations to properly work with the dynamic overlays */}
      {showIncomingInvitation && (
        <IncomingSketchSessionOverlay
          contact={contact!}
          accept={acceptSketchInvite}
          reject={rejectSketch}
        />
      )}
      {showSketchSession && (
        <SketchSessionOverlay
          isFullScreen={isFullScreen}
          toggleFullScreen={toggleFullScreen}
          active={sketchActive}
          // TODO: make ended into a state or use the active state?
          // eslint-disable-next-line react-compiler/react-compiler
          ended={!!resetSketchStateTimeoutRef.current}
          contact={contact}
          end={endSketch}
          sketchId={getSketchId}
        />
      )}
      {isShowInvitePopup && <InvitePopup togglePopup={toggleShowInvitePopup} />}
      {/* </AnimatePresence> */}
    </MotionConfig>
  );
}
